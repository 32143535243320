import { BaseWrapper, RequestConfig, RequestWrapper, ServerCode } from '@/define'
import { useUserStore } from '@/store'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { MD5 } from 'crypto-js'
import { assign, defaultsDeep, isArray } from 'lodash-es'
import qs from 'qs'
import api from './config'

/** 常规参数类型 */
type ParamsType = Record<string, string | number | string[] | number[] | undefined>

/** 请求数据格式 */
type RequestData = {
  /** 请求地址 */
  url: string
  /** 常规参数 */
  params?: ParamsType
  /** 头部通用参数 */
  headers?: HeadersKey | HeadersKey[]
  /** 完整axios配置 */
  config?: AxiosRequestConfig & RequestConfig
}

/**
 * GET请求
 * @param data GET请求数据
 * @returns
 */
export const get = <T extends BaseWrapper>(data: RequestData) =>
  transformWrapper(
    api.get<T>(
      data.url,
      defaultsDeep(
        {
          params: data.params,
          headers: assembleHeaders(data.headers, data.params),
        },
        data.config
      )
    )
  )

/**
 * POST请求
 * @param data POST请求数据
 * @returns
 */
export const post = <T extends BaseWrapper>(data: RequestData) =>
  transformWrapper(
    api.post<T>(
      data.url,
      data.params,
      defaultsDeep(
        {
          headers: assembleHeaders(data.headers, data.params),
        },
        data.config
      )
    )
  )

/**
 * 网络请求封装
 * @param promise
 * @param error
 * @returns
 */
const transformWrapper = <T extends BaseWrapper>(promise: Promise<AxiosResponse<T>>): Promise<RequestWrapper<T>> => {
  return promise.then((value) => ({
    success: value.data.code === ServerCode.SUCCESS,
    ...value.data,
  }))
}

export enum HeadersKey {
  /** 用户token */
  TOKEN,
  /** 验签sign */
  SIGN,
}

/** 获得用户token */
export const getToken = () => {
  return {
    token: useUserStore().token,
  }
}

/**
 * 获得验签sign
 * @param params body参数
 * @returns
 */
export const getSign = (params?: ParamsType) => {
  const timestamp = new Date().getTime()
  // 排序
  const signRule = qs.stringify(assign({ timestamp }, params), {
    // 忽略 value === null的键值对
    skipNulls: true,
    delimiter: '',
    sort: (a, b) => a.localeCompare(b),
  })

  // md5加密
  const sign = MD5(
    MD5(signRule)
      .toString()
      .concat(import.meta.env.VITE_SIGN_ENCRYPT_KEY)
  ).toString()

  return {
    timestamp,
    sign,
  }
}

type HeadersType = Partial<ReturnType<typeof getToken | typeof getSign>>
/**
 * 封装header通用参数
 * @param key 需要附带的封装参数类型
 * @param params body参数
 * @returns
 */
export const assembleHeaders = (key?: HeadersKey | HeadersKey[], params?: ParamsType): HeadersType => {
  const keys = isArray(key) ? key : [key]
  const headers: HeadersType = {}

  if (keys.includes(HeadersKey.TOKEN)) {
    // 获取用户token
    assign(headers, getToken())
  }

  if (keys.includes(HeadersKey.SIGN)) {
    // 获取加密sign
    assign(headers, getSign(params))
  }

  return headers
}
