import { BaseWrapper, DataWrapper } from '@/define'
import { get, HeadersKey, post } from '../helpers'

/**
 * 使用code兑换token
 * @param code 用户中心的登录code
 * @returns
 */
export const requestAuth = (code: string) =>
  get<DataWrapper<{ token: string }>>({
    url: 'v1/user/auth',
    params: { code },
  })

/** 获取用户登录信息 */
export const requestUserInfo = () =>
  get<DataWrapper<Response.User>>({
    url: 'v1/user/info',
    headers: HeadersKey.TOKEN,
  })

/** 提交申请入驻信息 */
export const submitApplyInfo = (params: Partial<Response.Apply>) =>
  post<BaseWrapper>({
    url: 'v1/user/apply',
    params,
    headers: HeadersKey.TOKEN,
  })

/** 获取用户认证信息 */
export const requestApplyInfo = () =>
  get<DataWrapper<Response.Apply>>({
    url: '/v1/user/apply',
    headers: HeadersKey.TOKEN,
  })
