import { requestAuth, requestUserInfo } from '@/api'
import { AuditStatus, IdentityType } from '@/define'
import { useStorage } from '@vueuse/core'
import { isEmpty } from 'lodash-es'
import { defineStore } from 'pinia'
import { useMessageStore } from './message'
import { useTeamStore } from './team'

const LOCAL_TOKEN = 'LOCAL_TOKEN'
const tokenRef = useStorage(LOCAL_TOKEN, '')

/** 用户信息 */
export const useUserStore = defineStore('user', {
  state: (): Store => {
    return {
      id: 0,
      token: tokenRef.value,
      nick: '',
      avatar: '',
      mobile: '',
      audit: AuditStatus.UNCOMMITTED,
      reason: '',
      identity: IdentityType.NORMAL,
    }
  },
  getters: {
    /** 是否登录 */
    isLogin: (state) => !isEmpty(state.mobile),
    /** 是否审核通过 */
    isAudit: (state) => state.audit === AuditStatus.PASS,
  },
  actions: {
    /** 授权 */
    async authToken(code: string) {
      const { success, data } = await requestAuth(code)
      if (!success) return

      this.token = tokenRef.value = data.token
      await this.getUserInfo(true)
    },

    /** 清除登录信息 */
    clearToken() {
      this.id = 0
      this.nick = ''
      this.avatar = ''
      this.mobile = ''
      this.token = ''
      this.audit = AuditStatus.UNCOMMITTED
      this.identity = IdentityType.NORMAL
      useTeamStore().clear()

      tokenRef.value = null
    },

    /** 获取用户信息 */
    async getUserInfo(force = false) {
      if ((!isEmpty(this.token) && !this.isLogin) || force) {
        try {
          const { success, data } = await requestUserInfo()
          if (success) {
            this.id = data.id
            this.nick = data.nickname
            this.avatar = data.avatar
            this.mobile = data.mobile
            this.audit = data.audit_status
            this.reason = data.reason
            this.identity = data.mode
            useTeamStore().getTeamInfo(data)
            useMessageStore().getNoticeNum()
          } else {
            this.clearToken()
          }
        } catch {
          this.clearToken()
        }
      }
    },
  },
})

type Store = {
  /** 用户id */
  id: number
  /** token */
  token: string
  /** 昵称 */
  nick: string
  /** 头像 */
  avatar: string
  /** 手机 */
  mobile: string
  /** 审核状态 */
  audit: AuditStatus
  /** 审核失败的信息 */
  reason: string
  /** 身份 */
  identity: IdentityType
}
