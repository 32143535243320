import { Layout, RouteName, TeamNature } from '@/define'
import { RouteRecordRaw } from 'vue-router'

export default <RouteRecordRaw>{
  path: '/design',
  component: Layout.DESIGN,
  meta: {
    requiresAudit: true,
    navigation: true,
  },
  children: [
    {
      path: '',
      redirect: { name: RouteName.TASK },
    },

    // 团队
    {
      path: 'team',
      name: RouteName.TEAM,
      meta: {
        title: '团队管理',
      },
      component: () => import('@views/team/TeamPage.vue'),
    },
    {
      path: `team/:id(\\d+)/:nature(${TeamNature.CREATE}|${TeamNature.PARTICIPATE})`,
      name: RouteName.TEAM_MEMBER,
      props: true,
      meta: {
        title: '成员管理',
      },
      component: () => import('@views/team/TeamMember.vue'),
    },

    // 任务
    {
      path: 'task',
      name: RouteName.TASK,
      meta: {
        title: '任务广场',
      },
      component: () => import('@views/task/TaskPage.vue'),
    },

    // 任务详情
    {
      path: 'task/detail/:id(\\d+)',
      name: RouteName.TASK_DETAIL,
      props: true,
      meta: {
        title: '任务详情',
      },
      component: () => import('@views/task/TaskDetail.vue'),
    },

    // 优秀作品
    {
      path: 'case',
      name: RouteName.CASE,
      meta: {
        title: '优秀作品',
      },
      component: () => import('@views/case/CasePage.vue'),
    },

    // 作品
    {
      path: 'work',
      name: RouteName.WORK,
      props: true,
      meta: {
        title: '作品管理',
      },
      component: () => import('@views/work/WorkPage.vue'),
    },

    // 数据统计
    {
      path: 'summary',
      name: RouteName.SUMMARY,
      meta: {
        title: '数据统计',
      },
      component: () => import('@views/summary/SummaryPage.vue'),
    },

    // 审核标准
    {
      path: 'standard',
      name: RouteName.STANDARD,
      meta: {
        title: '审核标准',
      },
      component: () => import('@views/static/StandardPage.vue'),
    },

    // 入驻协议
    {
      path: 'agreement',
      name: RouteName.AGREEMENT,
      meta: {
        title: '入驻协议',
      },
      component: () => import('@views/static/AgreementPage.vue'),
    },

    // 提现设置
    {
      path: 'cash',
      name: RouteName.CASH,
      meta: {
        title: '提现设置',
      },
      component: () => import('@views/cash/CashPage.vue'),
    },
  ],
}
