<script lang="ts" setup>
  import { AlignType, BtnType } from '@/define'
  import { computed, toRefs } from 'vue'

  const props = withDefaults(
    defineProps<{
      visible: boolean
      simple?: boolean
      title?: string
      content?: string
      description?: string
      btns?: {
        text: string
        loading?: boolean
        type?: BtnType
        align?: AlignType
      }[]
    }>(),
    {
      simple: false,
      title: '提示',
      content: '内容',
      btns: () => [],
    }
  )
  const emits = defineEmits<{ (e: 'update:visible', bool: boolean): void; (e: 'btnClick', text: string): void }>()

  const { btns } = toRefs(props)
  const leftBtns = computed(() => btns.value.filter((btn) => btn.align === AlignType.LEFT))
  const centerBtns = computed(() => btns.value.filter((btn) => btn.align === AlignType.CENTER))
  const rightBtns = computed(() => btns.value.filter((btn) => !btn.align))

  const updateVisible = (bool: boolean) => emits('update:visible', bool)

  const onBtnClick = (text: string) => emits('btnClick', text)
</script>
<template>
  <a-modal :simple="true" :mask-closable="false" :visible="visible" @update:visible="updateVisible">
    <div
      class="flex flex-nowrap items-center justify-between bg-blue-1 font-bold"
      :class="[simple ? 'h-10 px-4 text-sm' : 'h-14 px-4 text-xl']"
    >
      <span class="text-neutral">{{ title }}</span>

      <base-btn :type="BtnType.ICON" @click="updateVisible(false)">
        <icon-close-circle />
      </base-btn>
    </div>
    <slot>
      <div class="p-5">
        <div class="flex flex-nowrap items-center space-x-3 text-base font-bold text-neutral">
          <icon-exclamation-circle-fill class="flex-none text-xl text-orange" />
          <span class="truncate">{{ content }}</span>
        </div>
        <div class="indent-8 text-neutral-4">{{ description }}</div>
      </div>
    </slot>
    <div v-if="btns.length" :class="[simple ? ' px-5 pb-5' : 'px-5 py-3 border-t']">
      <div class="flex flex-nowrap">
        <div class="flex flex-nowrap justify-start flex-auto space-x-3">
          <base-btn
            v-for="(btn, index) in leftBtns"
            :key="index"
            :type="btn.type"
            :loading="btn.loading"
            @click="onBtnClick(btn.text)"
          >
            {{ btn.text }}
          </base-btn>
        </div>

        <div class="flex flex-nowrap justify-center flex-auto space-x-3">
          <base-btn
            v-for="(btn, index) in centerBtns"
            :key="index"
            :type="btn.type"
            :loading="btn.loading"
            @click="onBtnClick(btn.text)"
          >
            {{ btn.text }}
          </base-btn>
        </div>

        <div class="flex flex-nowrap justify-end flex-auto space-x-3">
          <base-btn
            v-for="(btn, index) in rightBtns"
            :key="index"
            :type="btn.type"
            :loading="btn.loading"
            @click="onBtnClick(btn.text)"
          >
            {{ btn.text }}
          </base-btn>
        </div>
      </div>
    </div>
  </a-modal>
</template>
