// SDK 调用方法管理
import { EventWrapper, useHandleMessage } from '@/utils/sdkEditor'
import { defineStore } from 'pinia'
import { Ref } from 'vue'

type SdkInstance = (message: EventWrapper) => void
// SDK通用页面接口实例
let sdkInstance = {} as SdkInstance
export const useSdkManageStore = defineStore({
  id: 'sdkManageStore',
  state: () => {
    return {}
  },
  actions: {
    init(iframeRef: Ref<HTMLIFrameElement | undefined>) {
      sdkInstance = useHandleMessage(iframeRef).postMessage
    },
  },
})
