import { ref, Ref } from 'vue'

const IFRAME_READY_MESSAGE = 'editor_iframe_ready'

/** 事件类型 */
export enum EventType {
  SHARE_CHAT = 'shareChat',
  SHOW_ACTIVITY = 'showActivity',
  SHOW_AWARD = 'showAward',
  SHOW_AD = 'showAd',
  SHOW_STARTUP = 'showStartup',
  HIDE = 'hide',
}

/** 分享数据 */
export type ShareData = {
  title: string
  desc: string
  icon: string
}

/** 分数数据包装 */
type ShareChatWrapper = {
  type: EventType.SHARE_CHAT
  data?: ShareData
}

/** 活动数据 */
export type ActivityData = {
  /** 3:游戏 6:营销 104:助力 */
  type: number | 3 | 6 | 104
  prizes: {
    /** 奖项名 */
    claim: string
    /** 奖品名 */
    name: string
    /** 奖品图片 */
    img: string
  }[]
  time: {
    start: string
    end: string
  }
  desc: string
}

/** 活动数据包装 */
type ShowActivityWrapper = {
  type: EventType.SHOW_ACTIVITY
  data?: ActivityData
}

/** 奖品数据 */
export type AwardData = {
  prize: {
    claim: string
    name: string
    img: string
  }
  time: {
    start: string
    end: string
  }
  rule: string
  cash:
    | {
        type: 1
        site: string[]
      }
    | {
        type: 2 | 3 | 4 | 5 | 6
      }
}

/** 奖品数据包装 */
type ShowAwardWrapper = {
  type: EventType.SHOW_AWARD
  data?: AwardData
}

/** 广告数据 */
export type AdData = {
  /** 图片 */
  img: string
}

/** 广告数据包装 */
type ShowAdWrapper = {
  type: EventType.SHOW_AD
  data?: AdData
}

/** 启动页数据 */
export type StartupData = {
  /** 背景 */
  bg: string
  /** 加载 */
  loading: string
}

/** 启动页数据包装 */
type ShowStartupWrapper = {
  type: EventType.SHOW_STARTUP
  data?: StartupData
}

/** 隐藏页面 */
type HideWrapper = {
  type: EventType.HIDE
  data?: {
    type:
      | EventType.SHARE_CHAT
      | EventType.SHOW_ACTIVITY
      | EventType.SHOW_AD
      | EventType.SHOW_AWARD
      | EventType.SHOW_STARTUP
  }
}

/** 事件包装 */
export type EventWrapper =
  | ShareChatWrapper
  | ShowActivityWrapper
  | ShowAwardWrapper
  | ShowAdWrapper
  | HideWrapper
  | ShowStartupWrapper

/** 调用编辑器调用事件 */
export const useHandleMessage = (iframeRef: Ref<HTMLIFrameElement | undefined>) => {
  const isReady = ref(false)
  const wrapperRef = ref<EventWrapper>()

  /**
   * 消息发送
   * iframe未准备完毕前会缓存最后一个发送对象
   * iframe准备完毕后自动发送缓存对象
   *
   * @param message
   */
  const postMessage = (message: EventWrapper) => {
    if (isReady.value) {
      iframeRef.value?.contentWindow?.postMessage(message, '*')
    } else {
      wrapperRef.value = message
    }
  }

  window.addEventListener('message', (e) => {
    if (e.data === IFRAME_READY_MESSAGE) {
      isReady.value = true
      wrapperRef.value && postMessage(wrapperRef.value)
    }
  })

  return {
    postMessage,
  }
}
