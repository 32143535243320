/** 文本居中 */
export const textCenter = () => {
  const setTextCenter = (el: HTMLElement) => {
    el.style.textAlign = 'center'
  }

  return {
    mounted(el: HTMLElement) {
      setTextCenter(el)
    },
  }
}
