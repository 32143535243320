import { DataWrapper } from '@/define'
import { get, HeadersKey, post } from '../helpers'

/** 获取用户编辑器配置
 * @param task_id 用户任务id
 */
export const requestEditorConfig = (task_id: string) =>
  get<DataWrapper<Response.EditorConfig>>({
    url: 'v1/task/get_editor_config',
    params: { task_id },
    headers: HeadersKey.TOKEN,
  })

/** 获取审核编辑器配置
 * @param task_id 用户任务id
 */
export const requestEditorAuditConfig = (user_task_id: string) =>
  get<DataWrapper<Response.EditorConfig>>({
    url: 'v1/task/get_editor_config',
    params: { user_task_id },
    headers: HeadersKey.TOKEN,
  })

/** 保存编辑器配置
 * @param task_id 用户任务id
 * @param config 编辑器配置信息
 */
export const requestSubmitEditorConfig = (task_id: number, config: string) =>
  post({
    url: 'v1/task/submit_editor_config',
    params: { task_id, config },
    headers: HeadersKey.TOKEN,
  })

/** 提交编辑器配置审核
 * @param task_id 用户任务id
 * @param name 模板名称
 */
export const requestSubmitEditorAudit = (task_id: number, name: string) =>
  post({
    url: 'v1/task/submit_editor_audit',
    params: { task_id, name },
    headers: HeadersKey.TOKEN,
  })

/** 获取素材分类列表
 * @param appscene_id 场景  1:通用 2:叠加包含互动营销 3:叠加包含大屏
 */
export const requestEditorMaterial = (appscene_id: '1' | '2' | '3') =>
  get<DataWrapper<Response.EditorMaterial>>({
    url: 'v1/task/get_material_cate_list',
    params: { appscene_id },
    headers: HeadersKey.TOKEN,
  })
