import { requestTeamInfoDetail } from '@/api'
import { RoleType } from '@/define'
import { defineStore } from 'pinia'

export const useTeamStore = defineStore('team', {
  state: (): Store => {
    return {
      id: 0,
      role: RoleType.MEMBER,
      name: '',
      cover: '',
      count: 0,
    }
  },
  getters: {
    /** 是否有团队信息 */
    hasTeam: (state) => state.id > 0,
    /** 是否是团队创建者 */
    isMaster: (state) => state.id > 0 && state.role === RoleType.CREATOR,
  },
  actions: {
    /** 设置当前默认团队信息 */
    async getTeamInfo(user: Response.User) {
      // 无团队信息
      if (user.team_id <= 0) {
        return
      }
      this.id = user.team_id
      this.role = user.role_id
      const { data } = await requestTeamInfoDetail(this.id)
      this.name = data.name
      this.cover = data.cover
      this.count = data.member_count
    },

    /** 请求团队信息 */
    clear() {
      this.id = 0
      this.role = RoleType.MEMBER
      this.name = ''
      this.cover = ''
      this.count = 0
    },
  },
})

type Store = {
  /** 团队id */
  id: number
  /** 当前角色 */
  role: RoleType
  /** 团队名称 */
  name: string
  /** 团队封面 */
  cover: string
  /** 团队人数 */
  count: number
}
