import { createRouter, createWebHistory } from 'vue-router'
import { getRoutes } from './helpers'

const router = createRouter({
  history: createWebHistory(),
  routes: getRoutes(),
  scrollBehavior() {
    return { top: 0 }
  },
})

export default router
