import { requestEditorAuditConfig, requestEditorConfig, requestEditorMaterial, requestSubmitEditorConfig } from '@/api'
import {
  ConfigType,
  createConfig,
  EditResType,
  IdentityType,
  ImageType,
  LogoType,
  PageType,
  ResImageBase,
  ResouceRecordType,
  ResTypeConst,
} from '@/define'
import resManage from '@views/editor/resManage'
import { isEmpty } from 'lodash-es'
import { defineStore } from 'pinia'
import { useRoute } from 'vue-router'

type EditorMaterial = {
  id: number
  label: string
  value: number
  appsceneId: number
  pid: number
  children: {
    label: string
    value: number
  }[]
}[]

let resManageInstance = {} as unknown as resManage
export const useEditorStore = defineStore('editor', {
  state: (): Store => {
    const route = useRoute()
    const query = route.query
    // 游戏类型 3-互动游戏，6-营销插件 100-大屏抽奖 101-大屏多人游戏 102-大屏单人游戏 103-3D主题签到
    const gameType = parseInt(query.game_type as string) || 3
    const isGameChild = true
    let sourceType = ''
    if (gameType === 6 || gameType === 104) {
      sourceType = 'online_marketing'
    } else if (gameType === 3) {
      sourceType = 'online_game'
    } else {
      sourceType = 'DP_GAME'
    }
    return {
      currentPage: {} as PageType,
      currentResData: {} as ResouceRecordType,
      sourceType,
      gameId: 0,
      source: '',
      token: '',
      currentPageKey: '',
      cateList: [],
      config: {} as ConfigType,
      is_online: 0,
      editable: {
        type: ResTypeConst.PAGE,
        data: {} as EditResType,
      },
      gameType,
      editObj: {} as EditResType,
      isGameChild,
      taskId: '',
      workName: '',
      templateData: '',
    }
  },
  actions: {
    async init(taskId: string, isAudit: string) {
      this.taskId = taskId
      const {
        data: { info },
      } =
        parseInt(isAudit) === IdentityType.AUDIT
          ? await requestEditorAuditConfig(taskId)
          : await requestEditorConfig(taskId)
      const { data } = await requestEditorMaterial('1')
      this.cateList = data.map((item) => {
        const arr: {
          label: string
          value: number
        }[] = []
        item.children.length &&
          item.children.forEach((el) =>
            arr.push({
              label: el.name,
              value: el.material_cate_id,
            })
          )
        const obj = {
          id: item.id,
          label: item.name,
          value: item.material_cate_id,
          appsceneId: item.appscene_id,
          pid: item.pid,
          children: arr,
        }
        return obj
      })
      if (info && info.config) {
        this.config = JSON.parse(info.config)
        this.is_online = info.is_online
      }
      this.workName = info.title
      this.templateData = info.template_data
      // 如果config为空 则创建配置文件对象
      if (isEmpty(this.config)) {
        this.config = this.createConfigForGameType()
      }
      // 初始化默认编辑器
      this.editable.data = this.config
      resManageInstance = new resManage({
        config: this.config,
        isGameChild: this.isGameChild,
      })
      this.instanceInitData()
    },
    instanceInitData() {
      this.currentPageKey = resManageInstance.currentPageKey
      this.currentPage = resManageInstance.currentPage
      this.currentResData = resManageInstance.currentResData
      this.editObj = resManageInstance.editObj
    },
    /**  保存模板资源配置 */
    saveGameResource() {
      this.setGameResource()
      return requestSubmitEditorConfig(parseInt(this.taskId), JSON.stringify(this.config))
    },
    /** 设置游戏可替换资源 */
    setGameResource() {
      resManageInstance.saveGameResouce()
    },
    /**
     * 根据不同gameType 创建不同的配置
     */
    createConfigForGameType(): ConfigType {
      if (this.gameType === 3) {
        return createConfig.hd()
      } else if (this.gameType === 6) {
        return createConfig.marking()
      } else if (this.gameType === 100) {
        return createConfig.screenLottery()
      } else if (this.gameType === 101) {
        return createConfig.multiplayer({ method: 1 })
      } else if (this.gameType === 102) {
        return createConfig.screenSingleGmae()
      } else if (this.gameType === 103) {
        return createConfig.sign()
      } else {
        return createConfig.hd()
      }
    },
    setEditorObj(obj: EditResType) {
      this.editObj = obj
    },
    /** 切换画布编辑器 */
    switchCanvasEditor() {
      this.switchEditor(ResTypeConst.CANVAS, this.config)
    },
    /** 切换组件编辑器 */
    switchEditor(type: ResTypeConst, data: EditResType) {
      this.editable.type = type
      this.editable.data = data
    },
    /** 切换页面编辑器 */
    switchPageEditor(page: PageType) {
      this.switchEditor(ResTypeConst.PAGE, page)
    },
    /** 切换图片编辑器 */
    switchImageEditor(image: ImageType | LogoType) {
      this.switchEditor(ResTypeConst.IMAGE, image)
    },
    changeCurrentPage(page: string) {
      resManageInstance.changeCurrentPage(page)
      this.instanceInitData()
    },
    addResForCurrentPage(item: ResImageBase) {
      resManageInstance.addResForCurrentPage(item)
      this.instanceInitData()
    },
  },
})

type Store = {
  currentPage: PageType
  currentResData: ResouceRecordType
  sourceType: string
  gameId: number
  source: string
  token: string
  currentPageKey: string
  cateList: EditorMaterial
  config: ConfigType
  is_online: number
  editable: {
    type: ResTypeConst
    data: EditResType
  }
  gameType: number
  editObj: EditResType
  isGameChild: boolean
  taskId: string
  workName: string
  templateData: string
}
