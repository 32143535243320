import { Message } from '@arco-design/web-vue'

/**
 * 未处理的类型
 * @param must 类型
 * @param msg 提示
 */
export const mustNever = (must: never, msg = '未处理的分支') => {
  Message.error(`${msg} - ${JSON.stringify(must)}`)
  throw new Error(`${msg} - ${must}`)
}

/**
 * 执行报错
 */
export const throwError = (error: unknown = '未处理的分支调用'): never => {
  throw new Error(String(error))
}
