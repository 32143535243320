/** 角色类型 */
export enum RoleType {
  /** 创建者 */
  CREATOR = 4,
  /** 管理员 */
  ADMIN,
  /** 成员 */
  MEMBER,
}

/** 团队性质 */
export enum TeamNature {
  /** 全部 */
  ALL,
  /** 我创建的 */
  CREATE,
  /** 我参与的 */
  PARTICIPATE,
}
