// 配置文件管理
// 页面：添加 删除 修改
// 素材: 添加 删除 修改
import {
  ConfigType,
  createPage,
  createResItem,
  EditResType,
  GameResourceType,
  ImageType,
  LogoType,
  PageRecodeType,
  PageType,
  ResImageBase,
  ResouceRecordType,
  ResouceType,
} from '@/define'
import { isEmpty, pullAll } from 'lodash-es'

export type MenuItem = {
  name: string
  key: string
}
export type PageMenu = Array<MenuItem>

export type ResOption = {
  config: ConfigType
  originGameConfig?: ConfigType
  isGameChild?: boolean
  gameMethod?: number
  isOnline?: boolean
}

export default class resManage {
  public config: ConfigType // 配置文件对象
  public id: number // 默认的素材id
  public pageId: number // 默认的页面id
  public currentPageKey: string // 当前编辑的页面id
  public editObj: EditResType // 当前编辑的资源对象
  public editId: string // 当前编辑资源id
  public currentPage: PageType // 当前页对象
  public currentResData: ResouceRecordType // 当前页面的资源
  public isGameChild: boolean // 是否是子模板
  public originGameConfig: ConfigType // 游戏原始配置
  public gameProsessMenu: PageMenu // 过程页菜单
  public fixGameMenu: PageMenu // 固定页菜单
  public gameMethod: number // 多人游戏是否是摇一摇类型的游戏
  public isOnline: boolean // 配置文件是否是上架状态
  constructor(option: ResOption) {
    const { config, originGameConfig, isGameChild, gameMethod, isOnline } = option
    this.config = config || {} // 当前配置文件对象
    this.id = 0 // 默认素材id 可自增
    if (!isEmpty(this.config)) {
      if (!isEmpty(this.config.resource)) {
        // 初始值 自增id
        this.id = Object.entries(this.config.resource).length
      }
    }
    this.pageId = 0 // 页面默认id 可自增
    this.currentPageKey = 'home' // 当前编辑的页面
    this.editObj = {} as EditResType
    this.editId = '' // 当前激活的素材id
    this.currentPage = {} as PageType // 当前激活的页面对象
    this.currentResData = {} as ResouceRecordType // 当前激活的页面素材对象
    this.isGameChild = isGameChild || false // 是否是子模板
    this.originGameConfig = originGameConfig || ({} as ConfigType) // 子模板配置
    // 固定页面菜单
    this.fixGameMenu = []
    // 游戏过程页面菜单
    this.gameProsessMenu = []
    this.gameMethod = gameMethod || 2
    this.isOnline = isOnline || false
    this.initId()
    this.initPageId()
    this.setMenu()
    this.changeCurrentPage('home')
  }
  // 设置当前页面 和 当前页面相关素材资源
  public changeCurrentPage(page: string) {
    this.currentPageKey = page
    this.currentPage = this.config.page[page]
    this.editObj = this.config.page[page]
    this.changeCurrentResData()
  }
  // 设置当前页面素材
  public changeCurrentResData() {
    // 过滤出属于当前页面的素材
    const childrenRes = this.config.page[this.currentPageKey].children
    const currentPageRes: ResouceRecordType = {}
    childrenRes.forEach((item) => {
      if (this.config.resource[item]) {
        currentPageRes[item] = this.config.resource[item]
      }
    })
    if ('logo' in this.currentPage) {
      currentPageRes['logo'] = this.currentPage.logo as LogoType
    }
    this.currentResData = currentPageRes
  }
  // 构建资源
  public createRes(item: ResImageBase) {
    const baseTemp = createResItem()
    baseTemp.page = this.currentPageKey
    this.id++
    baseTemp.zIndex = this.id
    baseTemp.id = this.id.toString()
    baseTemp.width = item.originWidth
    baseTemp.height = item.originHeight
    baseTemp.url = item.url
    baseTemp.originWidth = item.originWidth
    baseTemp.originHeight = item.originHeight
    baseTemp.code = item.name.split('.')[0]
    baseTemp.name = item.name
    return baseTemp
  }
  // 添加资源到对应页面
  public addResForPage(page: string, item: ResImageBase) {
    const res = this.createRes(item)
    this.config.page[page].children.push(res.id)
    this.config.resource[res.id] = res
    this.config.gameResource[res.id] = res
  }
  // 添加资源到当前页面
  public addResForCurrentPage(item: ResImageBase) {
    this.addResForPage(this.currentPageKey, item)
    this.changeCurrentResData()
  }
  // 删除资源
  public deleteRes(id: string) {
    for (const x in this.config.page) {
      let children = this.config.page[x].children
      children = children.filter((item) => item !== id)
      this.config.page[x].children = children
    }
    // 删除素材资源
    delete this.config.resource[id]
    // 删除游戏素材资源
    delete this.config.gameResource[id]
    this.changeCurrentResData()
  }
  // 新增游戏过程页面
  public addPage(): number {
    const pageTemp = createPage.base()
    pageTemp.catolog = 'game'
    this.pageId++
    pageTemp.id = this.pageId
    this.config.page[this.pageId] = pageTemp
    this.setMenu()
    return this.pageId
  }
  // 新增过程页面并切换到当前页面
  public addPageForCurrent() {
    const pageId = this.addPage().toString()
    this.changeCurrentPage(pageId)
  }
  // 检查id 是否重复
  public inspectRepeatId(): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
      const configResouce = this.config.resource
      const keys: Array<string> = []
      Object.entries(configResouce).forEach((item) => {
        keys.push(item[1].id)
      })
      const uni: Array<string> = []
      const repeat: Array<string> = []
      keys.forEach((item) => {
        // 重复的key
        if (uni.includes(item)) {
          repeat.push(item)
        } else {
          uni.push(item)
        }
      })
      if (repeat.length > 0) {
        resolve(repeat)
      } else {
        reject(repeat)
      }
    })
  }
  // 设置游戏可替换资源
  public resetGameResource() {
    let pageResouces: Array<string> = []
    const gameResource: GameResourceType = {}
    Object.entries(this.config.page).forEach((item) => {
      pageResouces = pageResouces.concat(item[1].children)
    })
    // 所有的素材资源
    const resouces = Object.keys(this.config.resource)
    // 返回被剔除的key值
    const resouceTemp: Array<string> = pullAll(resouces, pageResouces)
    // 如果资源跟页面资源不同步则删除多余的资源
    if (resouceTemp.length > 0) {
      resouceTemp.forEach((item) => {
        delete this.config.resource[item]
      })
    }

    try {
      Object.entries(this.config.resource).map((item) => {
        // 不是子模板
        if (!this.isGameChild) {
          if ('resource' in this.originGameConfig && item[0] in this.originGameConfig.resource) {
            const itemGameUrl = this.originGameConfig.resource[item[0]].url
            if (item[1].url !== itemGameUrl) {
              gameResource[item[0]] = item[1]
            }
          }
        } else {
          gameResource[item[0]] = item[1]
        }
      })
    } catch (err) {
      console.log(err)
    }
    this.config.gameResource = gameResource
  }

  /**
   * 后台配置文件保存 gameResource
   */
  public saveGameResouce() {
    const resource = this.config.resource
    const gameResource = this.config.gameResource
    // 同步音乐到gameResource中
    if ('music' in this.config && this.config.music.url !== '') {
      gameResource['music'] = this.config.music
    }
    Object.entries(resource).forEach((item) => {
      const key = item[0]
      const value = item[1]
      gameResource[key] = value
    })
  }
  // 加载图片
  public loadImg(data: ResouceType, isNeedCross = false): Promise<{ img: HTMLImageElement; data: ResouceType }> {
    return new Promise((resolve, reject) => {
      const img = new Image()
      // data.img = img
      if (isNeedCross) {
        img.setAttribute('crossOrigin', 'Anonymous')
      }
      img.onload = () => {
        resolve({
          img,
          data,
        })
      }
      img.onerror = reject
      img.src = data.url
    })
  }
  // 生成预览图
  public createPreviewImg(option: { page: string }): Promise<string> {
    const data = this.config
    if (!(option.page in data.page)) {
      return Promise.reject(new Error('配置文件没有手机页面，无法生成预览图'))
    }
    const ratio = window.devicePixelRatio || 1
    const canvas = document.createElement('canvas')
    const page = data.page[option.page]
    canvas.width = page.canvas.width * ratio // 实际渲染像素
    canvas.height = page.canvas.height * ratio // 实际渲染像素
    const context = canvas.getContext('2d')
    const ctx = context as unknown as {
      setTransform: (a: number, b: number, c: number, d: number, e: number, f: number) => void
      drawImage: (a: object, b: number, c: number, d: number, e: number) => void
    }
    ctx.setTransform(ratio, 0, 0, ratio, 0, 0)
    // 加载所有图片，等图片的都加载完毕以后，再绘制
    // 需要被绘制的素材资源
    const imgs: Array<ResouceType> = []
    const resource: ResouceRecordType = JSON.parse(JSON.stringify(data.resource))
    Object.entries(resource).forEach((item) => {
      const key = item[0]
      const val = item[1]
      if (page.children.includes(key)) {
        imgs.push(val)
      }
    })
    // 图片加载promise 对象
    const promiseFunc = imgs.map((item) => {
      return this.loadImg(item, true)
    })
    return Promise.all(promiseFunc)
      .then((res) => {
        // 根据图片的层级进行绘制
        const canvasResImgs = res.sort((a, b) => {
          const ca = a.data
          const cb = b.data
          return parseInt(ca.zIndex.toString()) - parseInt(cb.zIndex.toString())
        }) as unknown as Array<{ img: object } & ImageType>

        // 图片加载完毕开始绘制
        canvasResImgs.forEach((item) => {
          ctx.drawImage(item.img, item.x, item.y, item.width, item.height)
        })
        const dataUrl = canvas.toDataURL('image/png')
        return dataUrl
      })
      .catch((error) => {
        console.log(error)
        Promise.reject(error)
        return error
      })
  }
  /**
   *
   * @param {} data {url:'',width:'',height:''}
   *  图片地址存在且尚未拼接上自适应参数
   *  腾讯云参数：转换png + 缩放比例 + 填充透明色
   */
  public static reizeChangeUrl(data: ResouceType): string {
    let url = data.url
    if (url && !url.includes('?imageMogr2/')) {
      url = `${data.url}?imageMogr2/format/png|imageMogr2/thumbnail/${data.width}x${data.height}/pad/1/color/IzAwMDAwMDAw`
    }
    return url
  }
  /**
   *  实例类型的资源进行资源替换
   * @param {*} option:{id:number,url:string}
   */
  public replaceInstanceTypeEvent(option: { id: number; url: string }) {
    // 根据实例类型id返回资环对象
    const findInstansObjForId = (typeId: number) => {
      return Object.entries(this.config.resource)
        .filter((item) => {
          const obj = item[1]
          return obj.instanceType === typeId
        })
        .map((item) => item[1])
    }
    // 替换当前实例类型是typeId的资源图片
    const res = findInstansObjForId(option.id)
    if (res.length > 0) {
      // 实例类型资源所属的页面资源
      // page = findPageForResId(current[0].id)
      res.forEach((item) => {
        item.url = option.url
        const url = resManage.reizeChangeUrl(item as ResouceType)
        item.url = url
      })
    }
    // return page
  }
  public isNumber(val: string) {
    const regPos = /^[0-9]+.?[0-9]*/ //判断是否是数字。
    if (regPos.test(val)) {
      return true
    } else {
      return false
    }
  }
  /** 保存通用菜单 */
  public setMenu() {
    let pages = []
    this.fixGameMenu = []
    this.gameProsessMenu = []
    if (!isEmpty(this.config.page)) {
      pages = Object.entries(this.config.page)
    } else {
      return
    }
    pages.forEach((item) => {
      const key = item[0]
      const target = item[1]
      // const hasReplace = this.juadgeReplaceItem({ page: target, resource: this.config.resource })
      // 素材是否具有可替换资源
      // if (hasReplace) {
      const temp = {
        key,
        name: target.name,
        children: target.children,
      }
      // 新版本使用catolog 旧版本使用type 区分页面
      if ('catolog' in target) {
        if (target.catolog === 'page') {
          // 固定页
          this.fixGameMenu.push(temp)
        } else if (target.catolog === 'game') {
          // 游戏过程页
          this.gameProsessMenu.push(temp)
        }
      } else {
        // 旧版本兼容处理
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const obj = target as any
        if (obj.type === 'game') {
          // 固定页
          this.gameProsessMenu.push(temp)
        } else if (key !== 'loading') {
          this.fixGameMenu.push(temp)
          // 游戏过程页
        }
      }
    })
  }
  /** 删除过程页 */
  public deleteProcessMenu(index: number) {
    const page = this.gameProsessMenu[index]
    this.deletePage(page.key)
    this.gameProsessMenu.splice(index, 1)
  }
  /**  删除页面 */
  public deletePage(pageId: string) {
    const currentPage = this.config.page[pageId]
    // 删除页面对应的素材资源
    currentPage.children.forEach((item) => {
      this.deleteRes(item)
    })
    delete this.config.page[pageId]
  }
  /**  判断页面是否有替换素材 */
  public juadgeReplaceItem(obj: { page: PageType; resource: ResouceRecordType }) {
    let hasReplace = false
    // 如果页面没有可替换素材则不显示
    Object.entries(obj.resource).forEach((target) => {
      const key = target[0]
      const value = target[1]
      if (obj.page.children.includes(key) && value.replace === 1) {
        hasReplace = true
      }
    })
    return hasReplace
  }
  /** 修改画布大小 */
  public changeCanvas(option: { width: number; height: number }) {
    this.currentPage.canvas.width = option.width
    this.currentPage.canvas.height = option.height
  }
  public changeCurrentPageBg(url: string) {
    if ('bg' in this.currentPage && this.currentPage.bg) {
      this.currentPage.bg.url = url
    }
  }
  /**  resource id 跟key进行同步 */
  public idForKeySync(): Promise<boolean> {
    return new Promise((resolve) => {
      const configResouce: ResouceRecordType = JSON.parse(JSON.stringify(this.config.resource))
      let isChangeKey = false
      Object.entries(configResouce).forEach((item) => {
        const key = item[0]
        // key跟id不一致 需要进行同步 以id为主
        if (key !== item[1].id) {
          isChangeKey = true
          // 新增key对应资源
          this.config.resource[item[1].id] = item[1]
          // 删除key对应资源
          delete this.config.resource[key]

          // 新增key对应游戏资源
          this.config.gameResource[item[1].id] = item[1]
          // 删除key对应游戏资源
          delete this.config.gameResource[key]

          // 删除页面对应的资源
          Object.entries(this.config.page).forEach((target) => {
            const obj = target[1]
            // 删除原来的key 插入新的id
            if (obj.children.includes(key)) {
              const index = obj.children.indexOf(key)
              obj.children.splice(index, 1, item[1].id)
            }
          })
        }
      })
      resolve(isChangeKey)
    })
  }
  /**  删除过程页 */
  public deletePageMenu(index: number) {
    const page = this.gameProsessMenu[index]
    this.deletePage(page.key)
    this.gameProsessMenu.splice(index, 1)
  }
  // 设置自增id
  public getSortId(obj: ResouceRecordType | PageRecodeType): number {
    let idTemp: Array<number> = []
    let id = 0
    if (Object.keys(obj).length > 0) {
      for (const x in obj) {
        if (this.isNumber(x)) {
          const indexId = parseInt(x)
          if (indexId) {
            idTemp.push(indexId)
          }
        }
      }
      idTemp = idTemp.sort(function (a, b) {
        return a - b
      })
      if (idTemp.length > 0) {
        id = idTemp[idTemp.length - 1]
      }
    }
    return id
  }
  // 初始化自增资源id
  public initId() {
    const id = this.getSortId(this.config.resource)
    this.id = id
  }
  // 初始化自增页面id
  public initPageId() {
    const id = this.getSortId(this.config.page)
    this.pageId = id
  }
}
