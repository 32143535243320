import { DataWrapper, ListWrapper, PageWrapper, TeamNature } from '@/define'
import { get, HeadersKey, post } from '../helpers'

/** 获取团队列表
 * @param user_id 用户id
 * @param page 分页页码
 * @param limit 每页数量
 * @returns
 */
export const requestTeamListInfo = (nature: TeamNature, page = 1, limit = 10) =>
  get<PageWrapper<Response.Team>>({
    url: 'v1/team/list',
    params: { nature, page, limit },
    headers: HeadersKey.TOKEN,
  })

/** 获取全部团队列表
 * @returns
 */
export const requestUserTeamList = () =>
  get<ListWrapper<Response.Team>>({
    url: 'v1/team/user_team',
    headers: HeadersKey.TOKEN,
  })

/** 获取团队信息
 * @param team_id 团队ID
 * @returns
 */
export const requestTeamInfoDetail = (team_id: number) =>
  get<DataWrapper<Response.Team & { user_id: number }>>({
    url: 'v1/team/info',
    params: { team_id },
    headers: HeadersKey.TOKEN,
  })

/** 获取团队成员信息
 * @param team_id 团队ID
 * @returns
 */
export const requestTeamMemberInfo = (team_id: number, page = 1, limit = 20) =>
  get<PageWrapper<Response.TeamMember>>({
    url: 'v1/team/member_list',
    params: { team_id, page, limit },
    headers: HeadersKey.TOKEN,
  })

/** 创建团队
 * @param user_id 用户id
 * @param name 团队名称
 * @param profile 团队简介
 * @param cover 团队封面图
 * @returns
 */
export const requestCreateTeam = (name: string, profile: string, cover: string) =>
  post<DataWrapper<Response.Team>>({
    url: 'v1/user/create_team',
    params: { name, profile, cover },
    headers: HeadersKey.TOKEN,
  })

/** 编辑团队信息
 * @param id 唯一id
 * @param user_id 用户id
 * @param name 团队名称
 * @param profile 团队简介
 * @param cover 团队封面图
 * @returns
 */
export const requestUpdateTeam = (id: number, name: string, profile: string, cover: string) =>
  post<DataWrapper<Response.Team>>({
    url: 'v1/team/update_team',
    params: { id, name, profile, cover },
    headers: HeadersKey.TOKEN,
  })

/** 切换用户团队/个人
 * @param team_id 团队ID
 * @returns
 */
export const requestChangeTeam = (team_id: number) =>
  post({
    url: 'v1/user/change_team',
    params: { team_id },
    headers: HeadersKey.TOKEN,
  })

/** 邀请成员
 * @param team_id 团队ID
 * @param mobile_list 成员手机
 * @returns
 */
export const requestInviteMember = (team_id: number, mobile_list: string[]) =>
  post({
    url: 'v1/team/invite_member',
    params: { team_id, mobile_list },
    headers: HeadersKey.TOKEN,
  })

/** 移除成员
 * @param team_id 团队ID
 * @param id 成员ID
 * @returns
 */
export const requestRemoveMember = (team_id: number, id: string) =>
  post({
    url: 'v1/team/remove_member',
    params: { team_id, id },
    headers: HeadersKey.TOKEN,
  })

/** 退出团队
 * @param team_id 团队ID
 * @returns
 */
export const requestLeaveTeam = (team_id: number) =>
  post({
    url: 'v1/team/leave_team',
    params: { team_id },
    headers: HeadersKey.TOKEN,
  })

/** 解散团队
 * @param team_id 团队ID
 * @returns
 */
export const requestDisbandTeam = (team_id: number) =>
  post({
    url: 'v1/team/disband_team',
    params: { team_id },
    headers: HeadersKey.TOKEN,
  })
/**
 * 获取团队账单信息
 * @param team_id 团队id
 * @returns
 */
export const getAccountSummary = (team_id: number) =>
  get<DataWrapper<Response.AccountData>>({
    url: 'v1/team/account',
    params: { team_id },
    headers: HeadersKey.TOKEN,
  })

/**
 * 获取数据统计列表数据
 * @param page 页码
 * @param limit 分页数量
 * @returns
 */
export const getAccountData = (start: string, end: string, page: number, limit: number) =>
  get<DataWrapper<Response.AccountList>>({
    url: 'v1/team/account_data',
    params: { start, end, page, limit },
    headers: HeadersKey.TOKEN,
  })
