import { DataWrapper } from '@/define'
import { post } from '../helpers'

/** 用户登录 */
export const requestLogin = (
  params: { loginType: 1; mobile: string; password: string } | { loginType: 2; mobile: string; smsCode: string }
) =>
  post<DataWrapper<{ authCode: string }>>({
    url: 'v1/user/login',
    params,
    config: {
      baseURL: import.meta.env.VITE_USER_CENTER_URL,
    },
  })

/** 发送验证码 */
export const requestSendSmsCode = (mobile: string) =>
  post<DataWrapper<{ seconds: number }>>({
    url: 'v1/sms/sendCode',
    params: {
      mobile,
      scene: 'userLogin',
    },
    config: {
      baseURL: import.meta.env.VITE_USER_CENTER_URL,
    },
  })
