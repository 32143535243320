import { Icon, Modal } from '@arco-design/web-vue'
import BaseBtn from '@components/btn/BaseBtn.vue'
import BaseEmpty from '@components/empty/BaseEmpty.vue'
import BreakpointWrapper from '@components/wrapper/BreakpointWrapper.vue'
import ViewWrapper from '@components/wrapper/ViewWrapper.vue'
import BasePopup from '@popups/BasePopup.vue'
import { App } from 'vue'

export const setupArco = (app: App<Element>) => {
  Modal._context = app._context

  /** iconfont图标组件全局注册 */
  app.component(
    // 组件命名不要使用icon前缀，避免与arco动态加载规则混淆
    'BaseIcon',
    Icon.addFromIconFontCn({
      src: '//at.alicdn.com/t/font_412274_imlhjeivmoh.js',
    })
  )

  /** 常用的基础组件全局注册 */
  app.component('BaseBtn', BaseBtn)
  app.component('BaseEmpty', BaseEmpty)
  app.component('BasePopup', BasePopup)
  app.component('ViewWrapper', ViewWrapper)
  app.component('BreakpointWrapper', BreakpointWrapper)
}
