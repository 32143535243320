/** 登录类型 */
export enum LoginType {
  PASSWORD = 1,
  SMS,
}

/** 入驻类型 */
export enum ApplyType {
  PERSONAL = 1,
  TEAM,
}

/** 个人类型的身份 */
export enum PersonalType {
  /** 学生 */
  STUDENT = 1,
  /** 全职 */
  WORK,
  /** 自由工作者 */
  FREE,
}

/** 团队规模 */
export enum TeamScaleType {
  LESS_TEN = 1,
  LESS_THIRTY,
  LESS_FIFTY,
  LESS_HUNDRED,
  MORE_HUNDRED,
}

/** 身份状态 */
export enum IdentityType {
  /** 普通人员 */
  NORMAL = 1,
  /** 审核人员 */
  AUDIT,
}

/** 审核状态 */
export enum AuditStatus {
  /** 未提交 */
  UNCOMMITTED,
  /** 待审核 */
  PENDING,
  /** 已通过 */
  PASS,
  /** 已拒绝 */
  REJECT,
}
