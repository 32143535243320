import { FieldRule } from '@arco-design/web-vue'

export type RulesType<T> = {
  [P in keyof T]?: FieldRule | FieldRule[]
}

/** 不能有前后空格的验证规则 */
export const RULE_NOT_SPACE: FieldRule = {
  match: /^[^ ].+[^ ]$|^\S+$/gm,
  message: '请不要包含前后空格',
}
