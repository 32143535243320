import { useUserStore } from '@/store'
import { openLogin } from '@popups/helpers'

export const login = () => {
  const checkLogin = (el: HTMLElement) => {
    const userStore = useUserStore()
    el.addEventListener(
      'click',
      (e) => {
        if (!userStore.isLogin) {
          openLogin()
          e.cancelBubble = true
        }
      },
      {
        capture: true,
      }
    )
  }

  return {
    mounted(el: HTMLElement) {
      checkLogin(el)
    },
  }
}
