import { filter, flatMap, isEmpty, values } from 'lodash-es'
import { RouteRecordRaw } from 'vue-router'

// 动态导入的所有路由配置文件
const routeFiles = import.meta.globEager<Record<'default', RouteRecordRaw>>('./routes/*.ts')

/** 获取动态加载的路由配置 */
export const getRoutes = () =>
  flatMap(
    filter(values(routeFiles), (module) => !isEmpty(module.default)),
    (module) => module.default
  )
