import '@/asset/styles/index.less'
import { App } from 'vue'
import { setupArco } from './arco'
import { setupDirective } from './directive'
import { setupPinia } from './pinia'
import { setupRouter } from './router'

/** 初始化启动 */
export const setupBoot = (app: App<Element>) => {
  setupArco(app)
  setupPinia(app)
  setupRouter(app)
  setupDirective(app)
}
