import { setupBoot } from '@/boot'
import { AlignType, BtnType } from '@/define'
import { assign, isString } from 'lodash-es'
import { Component, createApp, createVNode, mergeProps, reactive } from 'vue'
import BasePopup from './BasePopup.vue'
import LoginPopup from './login/LoginPopup.vue'

/**
 * 触发登录
 */
export const openLogin = () => openCustomPopup(LoginPopup)

/**
 * 开启简单弹窗页面，没有底部按钮
 *
 * @param config 只传字符串将只显示图标跟文字，设置配置则调整标题及提示
 */
export const openEasyPopup = (
  config:
    | string
    | {
        title?: string
        content: string
        description?: string
        btns?: {
          text: string
          type?: BtnType
          align?: AlignType
        }[]
        onBtnClick?: (text: string) => void
      }
) =>
  openCustomPopup(BasePopup, assign({ simple: true, title: '提示' }, isString(config) ? { content: config } : config))

/**
 * 打开自定义弹窗
 * @param component 组件
 * @param props 参数
 * @returns 返回promise，then为弹窗关闭
 */
export const openCustomPopup = (component: Component, props?: Record<string, unknown>) => {
  const propsAssemble = reactive({ visible: true })
  if (props) {
    assign(propsAssemble, props)
  }

  const root = document.createElement('div')
  const parent = document.getElementById('app') ?? document.body
  parent.appendChild(root)
  const app = createApp({
    setup: () => {
      return () =>
        createVNode(
          component,
          mergeProps(propsAssemble, {
            'onUpdate:visible': (bool: boolean) => (propsAssemble.visible = bool),
            onClose: () => {
              app.unmount()
              parent.removeChild(root)
            },
            onHide: () => {
              propsAssemble.visible = false
            },
          })
        )
    },
  })

  setupBoot(app)
  app.mount(root)

  const close = () => {
    propsAssemble.visible = false
  }

  return {
    close,
  }
}
