import { requestNoticeNum } from '@/api'
import { useUserStore } from '@/store'
import { defineStore } from 'pinia'

/** 未读消息数量 */
export const useMessageStore = defineStore('message', {
  state: (): Store => {
    return {
      count: {
        all: 0,
        public: 0,
        system: 0,
      },
    }
  },
  actions: {
    /** 获取未读消息数量 */
    async getNoticeNum() {
      if (useUserStore().isLogin) {
        const { success, data } = await requestNoticeNum()
        if (success) {
          this.count.all = data.All
          this.count.public = data.Public
          this.count.system = data.System
        }
      }
    },
  },
})

type Store = {
  /** 数量 */
  count: {
    /** 全部 */
    all: number
    /** 平台公告 */
    public: number
    /** 系统消息 */
    system: number
  }
}
