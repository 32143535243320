import { DataWrapper, InterfaceMessageType, ListWrapper, MessageStatus, PageWrapper } from '@/define'
import { get, HeadersKey, post } from '../helpers'

/**
 * 消息弹窗接口
 * @param is_read 消息状态
 * @returns
 */
export const requestNoticeBell = (is_read: MessageStatus) =>
  get<ListWrapper<Response.MessageInfo>>({
    url: 'v1/notice/bell',
    params: { is_read },
    headers: HeadersKey.TOKEN,
  })

/**
 * 消息菜单列表接口
 * @param type 消息类型
 * @param page 页码
 * @param limit 每页数量
 * @returns
 */
export const requestNoticeList = (type = InterfaceMessageType.ALL, page: number, limit = 20) =>
  get<PageWrapper<Response.MessageInfo>>({
    url: 'v1/notice/list',
    params: { type, page, limit },
    headers: HeadersKey.TOKEN,
  })

/**
 * 消息已读
 * @param id 消息id
 * @returns
 */
export const requestNoticeRead = (id: number) =>
  post({
    url: 'v1/notice/read',
    params: { id },
    headers: HeadersKey.TOKEN,
  })

/**
 * 消息全部已读
 * @param type 消息类型
 * @returns
 */
export const requestNoticeReadAll = (type = InterfaceMessageType.ALL) =>
  post({
    url: 'v1/notice/read_all',
    params: { type },
    headers: HeadersKey.TOKEN,
  })

/**
 * 消息数量
 * @returns
 */
export const requestNoticeNum = () =>
  get<DataWrapper<Response.MessageNum>>({
    url: 'v1/notice/num',
    headers: HeadersKey.TOKEN,
  })

/**
 * 通知详情
 * @param id 消息id
 * @returns
 */
export const requestNoticeInfo = (id: string) =>
  get({
    url: 'v1/notice/info',
    params: { id },
    headers: HeadersKey.TOKEN,
  })
