/** 资源类型 */
export enum ResTypeConst {
  IMAGE = 'image',
  BG = 'bg',
  CANVAS = 'canvas',
  PAGE = 'page',
  MUSIC = 'music',
}

export enum ResTypeCatalog {
  IMAGE = 'image',
  LOGO = 'logo',
  RESBG = 'resBg',
}

export type BaseConfig = {
  canvas: {
    // 画布大小
    width: number
    height: number
  }
  screen: 'pc' | 'mobile' // pc 屏幕 mobile 手机
  device: 1 | 2 // 1 竖屏 2 横屏
}

// 配置文件类型
export type ConfigType = {
  music: {
    // 音乐
    url: string
    name: string
    replace: number | 0 | 1
  }
  gameResource: GameResourceType // 模板内部使用的资源配置
  resource: ResouceRecordType // 全部资源配置
  page: Record<string, PageType> // 页面配置
} & BaseConfig

// 游戏资源类型
export type GameResourceType = Record<string, ImageType | MusicType | LogoType>

export type ResouceRecordType = Record<string, ResouceType>

export type PageRecodeType = Record<string, PageType>

export type ResouceType = ImageType | LogoType | ResBgType
// 页面类型
export type PageType = {
  id: number | string
  name: string
  // 背景
  bg?: BgType
  // logo
  logo?: LogoType
  // 游戏素材
  children: Array<string>
  enable: 0 | 1 // 页面素材添加修改删除 1 可以 0 不可以
  type: ResTypeConst.PAGE
  catolog: 'page' | 'game' // page: 固定页  game: 模板过程页面
} & BaseConfig

// 背景类型
export type BgType = {
  url: string
  originWidth: number
  originHeight: number
  bgColor: string
  type: ResTypeConst.BG
}
export type BaseResourceType = {
  id: string
  rid: number // 素材库id
  catalog: Array<number> // 素材分类
  code: string // 资源标识
  replace: 0 | 1 // 是否可被替换
  instanceType: number // 实例类型id
}

export type ResImageBase = {
  url: string
  originWidth: number
  originHeight: number
  name: string
}
export type BaseImageType = {
  width: number
  height: number
  x: number
  y: number
  rotate: number
} & BaseResourceType &
  ResImageBase
// 素材基础配置类型
export type MaterialType = {
  name: string
  page: string
  zIndex: number
  drag: 0 | 1 // 是否可移动放大缩小
} & BaseImageType

// 资源配置类型
export type ImageType = {
  type: ResTypeCatalog.IMAGE
} & MaterialType

export type ResBgType = {
  type: ResTypeCatalog.RESBG
} & MaterialType
export type LogoType = {
  type: ResTypeCatalog.LOGO
} & MaterialType
// 音乐类型
export type MusicType = {
  name: string
  url: string
  replace: number | 0 | 1
}

/** 可编辑对象 */
export type EditResType = MusicType | ImageType | BgType | PageType | ConfigType | LogoType | ResBgType

/**
 * 构建基础游戏配置
 */
export const createConfig = {
  // 基础配置
  base(): ConfigType {
    return {
      music: createMusic(),
      gameResource: {},
      // 编辑器资源
      resource: {},
      page: {},
      ...createBaseConfig.mobile(),
    }
  },
  // 互动配置
  hd(): ConfigType {
    return {
      ...this.base(),
      page: {
        home: {
          ...createPage.mobile(),
          id: 'home',
        },
      },
    }
  },
  // 互动营销配置
  marking(): ConfigType {
    return {
      ...this.hd(),
    }
  },
  // 大屏抽奖配置
  screenLottery(): ConfigType {
    return {
      ...this.base(),
      ...createBaseConfig.pc(),
      page: {
        home: {
          ...createPage.screenHome(),
          id: 'home',
        },
        mobile: {
          ...createPage.screenMobile(),
          id: 'mobile',
        },
      },
    }
  },
  /**
   * 多人游戏配置
   * @param option {method: 1 | 2} 1: 点一点 划一划 2: 摇一摇
   * @returns
   */
  multiplayer(option: { method: 1 | 2 }): ConfigType {
    const mobile = option.method === 2 ? createPage.rockMobilePage() : createPage.screenMobile()
    return {
      ...this.base(),
      page: {
        gameReady: {
          ...createPage.gameReady(),
          id: 'gameReady',
        },
        home: {
          ...createPage.screenHome(),
          id: 'home',
        },
        mobile: {
          ...mobile,
          id: 'mobile',
        },
      },
    }
  },
  /**
   * 大屏单人游戏
   */
  screenSingleGmae() {
    return {
      ...this.base(),
      ...createBaseConfig.pc(),
      page: {
        home: {
          ...createPage.screenHome(),
        },
        mobile: {
          ...createPage.screenMobile(),
        },
      },
    }
  },
  /**
   * 签到
   */
  sign() {
    return {
      ...this.base(),
      page: {
        home: {
          ...createPage.screenHome(),
        },
      },
    }
  },
  /**
   * 助力
   */
  help() {
    return {
      ...this.base(),
      page: {
        home: {
          ...createPage.mobile(),
        },
      },
    }
  },
}

/**
 * 构建设备展示基础信息
 */
export const createBaseConfig = {
  mobile(): BaseConfig {
    return {
      canvas: createCanvas.mobile(),
      screen: 'mobile', // pc 屏幕 mobile 手机
      device: 1, // 1 竖屏 2 横屏
    }
  },
  // 互动配置
  pc(): BaseConfig {
    return {
      canvas: createCanvas.screen(),
      screen: 'pc',
      device: 1,
    }
  },
}

/**
 * 构建页面
 */
export const createPage = {
  base(): PageType {
    return {
      name: '页面',
      // 游戏素材
      children: [],
      enable: 1, // 页面素材添加修改删除 1 可以 0 不可以
      type: ResTypeConst.PAGE,
      catolog: 'page',
      id: 'home',
      ...createBaseConfig.mobile(),
    }
  },
  // 构建手机端首页
  mobile(): PageType {
    return {
      ...this.base(),
      name: '首页',
      screen: 'mobile',
    }
  },
  // 构建大屏手机端主页
  screenMobile(): PageType {
    return {
      ...this.mobile(),
    }
  },
  // 构建大屏主页
  screenHome(): PageType {
    return {
      ...this.base(),
      ...createBaseConfig.pc(),
    }
  },
  // 多人游戏准备页面
  gameReady(): PageType {
    return {
      ...this.base(),
      ...createBaseConfig.pc(),
      enable: 0,
      id: 'gameReady',
      logo: {
        ...createLogo.gameReay(),
      },
      name: '游戏准备页面',
    }
  },
  // 大屏手机端摇一摇页面
  rockMobilePage(): PageType {
    return {
      ...this.screenMobile(),
      enable: 0,
      bg: {
        ...createBg.mobile(),
      },
      logo: {
        ...createLogo.mobile(),
      },
    }
  },
}

/**
 * 构建背景
 * @returns
 */

const createBg = {
  base(): BgType {
    const canvas = createCanvas.mobile()
    return {
      type: ResTypeConst.BG,
      bgColor: '#000000',
      url: '',
      originWidth: 0,
      originHeight: 0,
      ...canvas,
    }
  },
  // 构建手机端背景
  mobile(): BgType {
    return {
      ...this.base(),
    }
  },
  // 构建大屏端背景
  screen(): BgType {
    const canvas = createCanvas.screen()
    return {
      ...this.base(),
      ...canvas,
    }
  },
}

/**
 * 构建画布
 * @returns
 */
export const createCanvas = {
  mobile() {
    return {
      width: 750,
      height: 1334,
    }
  },
  screen() {
    return {
      width: 1920,
      height: 1080,
    }
  },
  mobileBg() {
    return {
      width: 750,
      height: 1700,
    }
  },
}

/**
 * 构建logo
 */
const createLogo = {
  base(): LogoType {
    return {
      ...createResLogoItem(),
      name: 'logo',
    }
  },
  mobile(): LogoType {
    const canvas = createCanvas.mobile()
    const mobileItem = {
      originWidth: 480,
      orignHeight: 480,
      width: 480,
      height: 480,
      url: 'https://static.manyidea.cloud/uploads/game/crazyball/0/images/mobileimg.png',
    }
    const position = centerItem(canvas, mobileItem)
    return {
      ...this.base(),
      ...mobileItem,
      ...position,
      page: 'mobile',
    }
  },
  gameReay(): LogoType {
    const canvas = createCanvas.screen()
    const screenItem = {
      originWidth: 1160,
      orignHeight: 535,
      width: 1160,
      height: 535,
      url: 'https://static.manyidea.cloud/static/dxhd_brand_saas_front/alpha/activitytool/static/img/logo.8915f886.png',
    }
    const position = centerItem(canvas, screenItem)
    return {
      ...this.base(),
      ...screenItem,
      ...position,
      page: 'gameReay',
    }
  },
}

// 构建素材配置
export const createMaterialItem = (): MaterialType => {
  return {
    id: '',
    name: '',
    url: '',
    originWidth: 0,
    originHeight: 0,
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    rotate: 0,
    page: 'game',
    replace: 1,
    rid: 0,
    catalog: [],
    zIndex: 1,
    code: '',
    instanceType: 0,
    drag: 0,
  }
}

// 构建素材配置
export const createResItem = (): ImageType => {
  return {
    ...createMaterialItem(),
    type: ResTypeCatalog.IMAGE,
  }
}

// 构建素材配置
export const createResLogoItem = (): LogoType => {
  return {
    ...createMaterialItem(),
    name: 'logo',
    type: ResTypeCatalog.LOGO,
  }
}

// 构建音乐素材
export const createMusic = () => {
  return {
    url: '',
    name: '',
    replace: 1,
  }
}

/**
 * 素材居中
 * @param canvas {width:number,height:number}
 * @param item {width: number,height:number}
 * @returns {x: number,y:number}
 */
const centerItem = (canvas: { width: number; height: number }, item: { width: number; height: number }) => {
  return {
    x: canvas.width / 2 - item.width / 2,
    y: canvas.height / 2 - item.height / 2,
  }
}
