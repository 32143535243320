<script lang="ts" setup>
  import { BtnType } from '@/define'
  import { LoginPopupTab, useLoginTab } from './helpers'
  import LoginPopupDefault from './LoginPopupDefault.vue'
  import LoginPopupForget from './LoginPopupForget.vue'
  import LoginPopupRegister from './LoginPopupRegister.vue'

  defineProps<{ visible: boolean }>()
  const emits = defineEmits<{ (e: 'update:visible', bool: boolean): void }>()
  const updateVisible = (bool: boolean) => emits('update:visible', bool)

  const tabRef = useLoginTab()
</script>
<template>
  <a-modal :mask-closable="false" :visible="visible" @update:visible="updateVisible" :simple="true" width="auto">
    <div class="login-popup flex">
      <div class="w-60 banner bg-cover bg-center px-8 py-11">
        <base-icon type="icon-a-LOGO3x" :size="32" />
        <div class="text-white font-bold text-lg">欢迎来到创作者中心</div>
        <div class="text-white text-xs">马上开始，一起加入创作</div>
      </div>
      <div class="p-8 w-96">
        <base-btn :type="BtnType.ICON" class="absolute top-4 right-4" @click="updateVisible(false)">
          <icon-close-circle />
        </base-btn>
        <a-tabs :active-key="tabRef" :animation="true">
          <a-tab-pane :key="LoginPopupTab.DEFAULT"> <login-popup-default @close="updateVisible(false)" /> </a-tab-pane>
          <a-tab-pane :key="LoginPopupTab.FORGET"> <login-popup-forget /> </a-tab-pane>
          <a-tab-pane :key="LoginPopupTab.REGISTER"> <login-popup-register /> </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </a-modal>
</template>
<style lang="less" scoped>
  .login-popup {
    .banner {
      background-image: url('@images/banner_login.png');
    }

    :deep(.arco) {
      &-tabs {
        &-nav {
          @apply hidden;
        }

        &-content {
          @apply p-0;
        }
      }

      &-form {
        &-item {
          @apply m-0;
        }
      }

      &-checkbox {
        &-label {
          @apply text-xs text-neutral-3;
        }
      }
    }
  }
</style>
