import router from '@/router'
import { addRouterGuard } from '@/router/guard'
import { addRouterProgress } from '@/router/progress'
import { App } from 'vue'

export const setupRouter = (app: App<Element>) => {
  app.use(router)
  addRouterGuard(router)
  addRouterProgress(router)
}
