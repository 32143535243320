import { Layout, RouteName } from '@/define'
import { RouteRecordRaw } from 'vue-router'

export default <RouteRecordRaw[]>[
  {
    path: '/',
    name: RouteName.HOMEPAGE,
    component: () => import('@views/root/HomePage.vue'),
  },
  {
    path: '/license',
    component: Layout.DEFAULT,
    children: [
      {
        path: '',
        name: RouteName.LICENSE,
        meta: {
          title: '用户协议',
          simplify: true,
        },
        component: () => import('@views/root/UserAgreement.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    component: Layout.DEFAULT,
    children: [
      {
        path: '',
        meta: {
          title: '404',
          simplify: true,
        },
        name: RouteName.NOT_FOUND,
        component: () => import('@views/root/NotFound.vue'),
      },
    ],
  },
]
