<script lang="ts" setup>
  import { BtnType } from '@/define'
  import { mustNever } from '@/utils'
  import { computed, toRefs } from 'vue'
  import { RouteLocationRaw } from 'vue-router'

  const props = withDefaults(defineProps<{ type?: BtnType; to?: RouteLocationRaw }>(), { type: BtnType.CONFIRM })
  const { type } = toRefs(props)

  // 类型对应的样式配置
  const typeRef = computed(() => {
    switch (type.value) {
      case BtnType.CONFIRM:
        return 'primary'
      case BtnType.CANCEL:
        return 'secondary'
      case BtnType.DANGER:
      case BtnType.PRIMARY:
        return 'outline'
      case BtnType.ICON:
        return 'text'
      default:
        mustNever(type.value)
        return undefined
    }
  })
  const statusRef = computed(() => (type.value === BtnType.DANGER ? 'danger' : undefined))
  const shapeRef = computed(() => (type.value === BtnType.ICON ? 'circle' : undefined))
</script>
<template>
  <template v-if="to">
    <router-link :to="to">
      <a-button v-bind="$attrs" class="base-btn" :type="typeRef" :status="statusRef" :shape="shapeRef">
        <template v-if="$slots.icon" #icon>
          <slot name="icon" />
        </template>
        <slot />
      </a-button>
    </router-link>
  </template>
  <template v-else>
    <a-button class="base-btn" :type="typeRef" :status="statusRef" :shape="shapeRef">
      <template v-if="$slots.icon" #icon>
        <slot name="icon" />
      </template>
      <slot />
    </a-button>
  </template>
</template>
