import { redirectOfAdmin, redirectOfAudit, redirectOfLogin, setTitle } from '@/composable'
import { useUserStore } from '@/store'
import { Router } from 'vue-router'

export const addRouterGuard = (router: Router) => {
  router.beforeEach((to) => {
    setTitle(to.meta.title)

    if (to.meta.requiresAdmin) {
      return redirectOfAdmin()
    } else if (to.meta.requiresAudit) {
      return redirectOfAudit()
    } else if (to.meta.requiresLogin) {
      return redirectOfLogin()
    } else {
      useUserStore().getUserInfo()
    }
  })
}
