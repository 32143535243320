/** 布局类型组件 */
export const Layout = {
  DEFAULT: () => import('@layouts/DefaultLayout.vue'),
  CUSTOM: () => import('@layouts/CustomSideLayout.vue'),
  DESIGN: () => import('@layouts/DesignSideLayout.vue'),
}

/** 路由名 */
export enum RouteName {
  /** 主页 */
  HOMEPAGE = 'HOMEPAGE',
  /** 用户协议 */
  LICENSE = 'LICENSE',
  /** 申请入驻 */
  APPLY = 'APPLY',
  /** 优秀作品 */
  CASE = 'CASE',
  /** 任务列表 */
  TASK = 'TASK',
  /** 任务详情 */
  TASK_DETAIL = 'TASK_DETAIL',
  /** 作品管理 */
  WORK = 'WORK',
  /** 作品编辑 */
  EDITOR = 'EDITOR',
  /** 团队列表 */
  TEAM = 'TEAM',
  /** 团队成员 */
  TEAM_MEMBER = 'TEAM_MEMBER',
  /** 数据统计 */
  SUMMARY = 'SUMMARY',
  /** 审核标准 */
  STANDARD = 'STANDARD',
  /** 入驻协议 */
  AGREEMENT = 'AGREEMENT',
  /** 提现设置 */
  CASH = 'CASH',
  /** 消息中心 */
  MESSAGE = 'MESSAGE',
  /** 审核中心 */
  EXAMINE = 'EXAMINE',
  /** 需求中心 */
  DEMAND = 'DEMAND',
  /** 404 */
  NOT_FOUND = 'NOT_FOUND',
}
