import { AuditStatus, IdentityType, RouteName } from '@/define'
import { useTeamStore, useUserStore } from '@/store'
import { mustNever } from '@/utils'
import { Message } from '@arco-design/web-vue'

/** 账户登录状态的验证 */
export const redirectOfLogin = async () => {
  const userStore = useUserStore()
  await userStore.getUserInfo()
  if (!userStore.isLogin) {
    Message.error('账号未登录或失效过期，请重新登录')
    return { name: RouteName.HOMEPAGE }
  }
}

/** 账号认证状态的重定向 */
export const redirectOfAudit = async () => {
  const userStore = useUserStore()

  const login = await redirectOfLogin()
  if (login) return login

  switch (userStore.audit) {
    case AuditStatus.PASS:
      break
    case AuditStatus.UNCOMMITTED:
    case AuditStatus.PENDING:
    case AuditStatus.REJECT:
      if (!useTeamStore().hasTeam) {
        Message.error('账号无权限')
        return { name: RouteName.HOMEPAGE }
      }
      break
    default:
      mustNever(userStore.audit, '无法识别的账号认证状态')
  }
}

/** 身份认证状态的重定向 */
export const redirectOfAdmin = async () => {
  const userStore = useUserStore()

  const login = await redirectOfLogin()
  if (login) return login

  switch (userStore.identity) {
    case IdentityType.NORMAL: {
      const audit = await redirectOfAudit()
      if (audit) {
        return audit
      } else {
        Message.error('账号无权限，请确认管理员账号或重新登录')
        return { name: RouteName.HOMEPAGE }
      }
    }
    case IdentityType.AUDIT:
      break
    default:
      mustNever(userStore.identity, '无法识别的用户身份')
  }
}
