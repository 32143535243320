/** 自定义按钮类型 */
export enum BtnType {
  /** 蓝底白字 */
  CONFIRM,
  /** 无底灰字灰框 */
  CANCEL,
  /** 无底红字红框 */
  DANGER,
  /** 无底蓝字蓝框 */
  PRIMARY,
  /** 无底灰字 */
  ICON,
}

/** 组件对齐类型 */
export enum AlignType {
  RIGHT,
  CENTER,
  LEFT,
}

/** 上传业务类型 */
export enum UploadType {
  /** 用户信息(头像,资质等) */
  USER = 1,
  /** 团队封面 */
  TEAM,
  /** 任务(初稿图片, 背景,psd,审核富文本等) */
  TASK,
}

/** 上传格式类型 */
export enum UploadFormatType {
  PNG,
  JPG,
  GIF,
  MP4,
  PSD,
  ZIP,
  RAR,
  SEVEN_ZIP,
}

/** 消息类型 */
export enum NoticeMode {
  /** 全部 */
  ALL = 'all',
  /** 公告 */
  PUBLIC = 'public',
  /** 系统消息 */
  SYSTEM = 'system',
}

/** 接口参数的消息类型 */
export enum InterfaceMessageType {
  /** 全部 */
  ALL,
  /** 公告 */
  PUBLIC,
  /** 系统消息 */
  SYSTEM,
}

/** 消息状态 */
export enum MessageStatus {
  /** 全部 */
  ALL,
  /** 未读 */
  UNREAD,
  /** 已读 */
  HAVE_READ,
}
