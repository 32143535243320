import { requestLogin, requestSendSmsCode } from '@/api'
import { AuditStatus, LoginType, RouteName } from '@/define'
import { useTeamStore, useUserStore } from '@/store'
import { mustNever } from '@/utils'
import { storeToRefs } from 'pinia'
import { computed, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useCountdown } from './countdown'
import { RulesType, RULE_NOT_SPACE } from './rules'

/** 登录操作逻辑 */
export const useLoginAction = () => {
  const userStore = useUserStore()
  const { isLogin } = storeToRefs(userStore)
  const router = useRouter()

  const loadings = reactive({
    sms: false,
    send: false,
  })

  const form = reactive({
    type: LoginType.SMS,
    mobile: '',
    password: '',
    smsCode: '',
  })
  const rules = computed<RulesType<typeof form>>(() => {
    return {
      mobile: [
        {
          required: true,
          message: '请输入手机号',
        },
        { match: /^[1-9][0-9]{10}$/, message: '请输入正确的手机号' },
      ],
      password:
        form.type === LoginType.PASSWORD
          ? [
              {
                required: true,
                message: '请输入密码',
              },
              { minLength: 6, message: '请输入至少6位的密码' },
              RULE_NOT_SPACE,
            ]
          : [],
      smsCode:
        form.type === LoginType.SMS
          ? [
              {
                required: true,
                message: '请输入验证码',
              },
              { minLength: 6, message: '请输入正确的验证码' },
              RULE_NOT_SPACE,
            ]
          : [],
    }
  })

  // 提交登录信息
  const onSubmit = async () => {
    loadings.send = true
    const { success, data } = await requestLogin(
      form.type === LoginType.SMS
        ? {
            loginType: LoginType.SMS,
            mobile: form.mobile,
            smsCode: form.smsCode,
          }
        : {
            loginType: LoginType.PASSWORD,
            mobile: form.mobile,
            password: form.password,
          }
    )
    if (success) {
      await userStore.authToken(data.authCode)
    }
    loadings.send = false
  }

  // 登录后重定向
  const redirectAfterLogin = () => {
    switch (userStore.audit) {
      case AuditStatus.PASS:
        router.push({ name: RouteName.TASK })
        break
      case AuditStatus.UNCOMMITTED:
      case AuditStatus.PENDING:
      case AuditStatus.REJECT:
        if (!useTeamStore().hasTeam) {
          router.push({ name: RouteName.APPLY })
        } else {
          router.push({ name: RouteName.TASK })
        }
        break
      default:
        mustNever(userStore.audit, '无法识别的账号认证状态')
    }
  }

  // 消息发送倒计时
  const { count, isFinished, restart } = useCountdown()
  const onSendSms = async () => {
    loadings.sms = true
    const { success, data } = await requestSendSmsCode(form.mobile)
    loadings.sms = false
    success && restart(data.seconds)
  }

  return {
    loadings,
    form,
    rules,
    onSubmit,
    isLogin,
    count,
    isFinished,
    onSendSms,
    redirectAfterLogin,
  }
}
