import { Layout, RouteName } from '@/define'
import { RouteRecordRaw } from 'vue-router'

export default <RouteRecordRaw>{
  path: '/admin',
  component: Layout.CUSTOM,
  meta: {
    requiresAdmin: true,
    navigation: true,
  },
  children: [
    {
      path: '',
      redirect: { name: RouteName.EXAMINE },
    },

    {
      path: 'examine',
      name: RouteName.EXAMINE,
      meta: {
        title: '审核中心',
      },
      components: {
        default: () => import('@views/admin/ExaminePage.vue'),
        sider: () => import('@components/side/AdminSider.vue'),
      },
    },

    // 任务详情
    {
      path: 'task/detail/:id(\\d+)',
      props: true,
      meta: {
        title: '任务详情',
      },
      components: {
        default: () => import('@views/task/TaskDetail.vue'),
        sider: () => import('@components/side/AdminSider.vue'),
      },
    },

    {
      path: 'demand',
      name: RouteName.DEMAND,
      meta: {
        title: '需求中心',
      },
      components: {
        default: () => import('@views/admin/DemandPage.vue'),
        sider: () => import('@components/side/AdminSider.vue'),
      },
    },
  ],
}
