import { requestNoticeBell, requestNoticeList, requestNoticeRead, requestNoticeReadAll } from '@/api'
import { InterfaceMessageType, MessageStatus, NoticeMode } from '@/define'
import { useMessageStore, useUserStore } from '@/store'
import { useDebounceFn } from '@vueuse/core'
import { ref, Ref } from 'vue'

/** 消息列表 */
type MessageInfo = {
  id: number
  /** 消息类型 */
  type: InterfaceMessageType
  /** 消息标题 */
  title: string
  /** 消息内容 */
  content: string
  /** 消息状态*/
  isRead: boolean
  /** 发送时间 */
  sendAt: string
}

const PAGE_NAMES: Record<NoticeMode, string> = {
  [NoticeMode.ALL]: '全部',
  [NoticeMode.PUBLIC]: '平台公告',
  [NoticeMode.SYSTEM]: '系统消息',
}
/** 类型对应的名称 */
export const getPageName = (type: NoticeMode) => PAGE_NAMES[type]

/** 接口参数的消息类型 */
const MODE_OF_TYPE: Record<NoticeMode, InterfaceMessageType> = {
  [NoticeMode.ALL]: InterfaceMessageType.ALL,
  [NoticeMode.PUBLIC]: InterfaceMessageType.PUBLIC,
  [NoticeMode.SYSTEM]: InterfaceMessageType.SYSTEM,
}

/**
 * 消息列表
 * @param type
 * @returns
 */
export const useMessageList = (type: Ref<NoticeMode>) => {
  const page = ref(1)
  const bottom = ref(false)
  const list = ref<MessageInfo[]>([])
  const loading = ref(false)

  const getList = async () => {
    loading.value = true
    const { data } = await requestNoticeList(MODE_OF_TYPE[type.value], page.value)
    if (data.last_page === page.value) {
      bottom.value = true
    }
    list.value.push(
      ...data.list.map((item) => {
        return {
          id: item.id,
          type: item.type,
          title: item.title,
          content: item.content,
          isRead: item.is_read === MessageStatus.HAVE_READ ? true : false,
          sendAt: item.send_at.substring(0, 16),
        }
      })
    )
    loading.value = false
  }

  const nextPage = () => {
    if (!loading.value && !bottom.value) {
      page.value++
      return getList()
    }
    return Promise.resolve()
  }

  const reset = () => {
    page.value = 1
    bottom.value = false
    return getList()
  }

  return { list, page, bottom, loading, getList, nextPage, reset }
}

export const useMessagePopupList = (): {
  /** 列表 */
  list: Ref<MessageInfo[]>
  /** 消息状态 */
  readStatus: Ref<MessageStatus>
  /** 切换标签 */
  changeStatus: (status: MessageStatus) => void
  /** 重置 */
  reset: () => Promise<void>
} => {
  const readStatus = ref(MessageStatus.UNREAD)
  const list = ref<MessageInfo[]>([])
  const getList = async () => {
    if (!useUserStore().isLogin) return Promise.resolve()
    const { data } = await requestNoticeBell(readStatus.value)
    list.value = data.list.map((item) => {
      return {
        id: item.id,
        type: item.type,
        title: item.title,
        content: item.content,
        isRead: item.is_read === MessageStatus.HAVE_READ ? true : false,
        sendAt: item.send_at.substring(0, 16),
      }
    })
  }

  const changeStatus = (status: MessageStatus) => {
    readStatus.value = status
    reset()
  }

  const reset = () => getList()

  return { list, readStatus, reset, changeStatus }
}

/** 当最后的已读接口请求结束两秒后更新消息数量 */
const debouncedFn = useDebounceFn(() => {
  useMessageStore().getNoticeNum()
}, 2000)

/** 标记已读
 * @param id 消息id
 * @param isRead 消息状态
 */
export const noticeRead = async (id: number, isRead: boolean) => {
  if (!isRead) {
    const { success } = await requestNoticeRead(id)
    if (success) {
      debouncedFn()
      return Promise.resolve(id)
    }
  }
}

/** 标记全部已读
 * @param type 消息类型
 */
export const noticeReadAll = async (type: NoticeMode) => {
  const { success } = await requestNoticeReadAll(MODE_OF_TYPE[type])
  if (success) {
    useMessageStore().getNoticeNum()
    return Promise.resolve()
  } else {
    return Promise.reject()
  }
}
