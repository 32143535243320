<script lang="ts" setup>
  import { useLoginAction } from '@/composable'
  import { LoginType } from '@/define'
  import { ref } from 'vue'

  const emits = defineEmits<{ (e: 'close'): void }>()

  const validateTrigger: ('blur' | 'change' | 'input')[] = ['blur']

  const { loadings, form, rules, onSubmit, isLogin, count, isFinished, onSendSms, redirectAfterLogin } =
    useLoginAction()

  const onSubmitSuccess = async () => {
    await onSubmit()
    if (isLogin.value) {
      emits('close')
      redirectAfterLogin()
    }
  }

  const password = ref(true)
</script>

<template>
  <div class="font-bold text-xl">登录你的账号</div>

  <a-form class="mt-4" :model="form" layout="vertical" size="large" :rules="rules" @submit-success="onSubmitSuccess">
    <a-form-item field="type">
      <a-radio-group v-model="form.type" type="button">
        <a-radio :value="LoginType.SMS">短信验证登录</a-radio>
        <a-radio :value="LoginType.PASSWORD">账号密码登录</a-radio>
      </a-radio-group>
    </a-form-item>

    <a-form-item field="mobile" hide-asterisk :validate-trigger="validateTrigger">
      <a-input v-model="form.mobile" placeholder="请输入手机号" allow-clear />
    </a-form-item>

    <a-form-item
      v-if="form.type === LoginType.PASSWORD"
      field="password"
      hide-asterisk
      :validate-trigger="validateTrigger"
    >
      <a-input v-model="form.password" placeholder="请输入密码" allow-clear :type="password ? 'password' : 'text'">
        <template #suffix>
          <base-icon
            class="text-neutral-3 hover:text-neutral-5 cursor-pointer"
            :type="password ? 'icon-bbiyan1' : 'icon-zhengyan1'"
            size="16"
            @click="password = !password"
          />
        </template>
      </a-input>
    </a-form-item>

    <a-form-item
      v-else-if="form.type === LoginType.SMS"
      field="smsCode"
      hide-asterisk
      content-class="space-x-3"
      :validate-trigger="validateTrigger"
    >
      <a-input v-model="form.smsCode" placeholder="请输入验证码" allow-clear />
      <base-btn :disabled="!isFinished" :loading="loadings.sms" @click="onSendSms">{{
        isFinished ? '发送短信' : `重新发送(${count})`
      }}</base-btn>
    </a-form-item>

    <a-form-item>
      <base-btn html-type="submit" long :loading="loadings.send">{{
        form.type === LoginType.SMS ? '新用户注册/已有账号登录' : '登录'
      }}</base-btn>
    </a-form-item>
  </a-form>

  <div class="mt-6 text-center text-xs">
    <template v-if="form.type === LoginType.SMS"> 未注册账号自动创建为新账号<br /> </template>
    登录注册均视为已同意<a-link href="/license" target="_blank" class="text-xs">用户协议</a-link>
  </div>
</template>
