import App from '@/App.vue'
import { setupBoot } from '@/boot'
import { createApp, h, Suspense } from 'vue'

console.info(`创作者中心版本:${APP_VERSION} \b 部署环境:${import.meta.env.VITE_DEPLOY_NAME}`)

const app = createApp({
  setup() {
    return () => h(Suspense, [h(App)])
  },
})

setupBoot(app)

app.mount('#app')
