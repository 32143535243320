import { IdentityType, Layout, RouteName } from '@/define'
import { RouteRecordRaw } from 'vue-router'

export default <RouteRecordRaw>{
  path: '/editor',
  component: Layout.CUSTOM,
  meta: {
    requiresAudit: true,
    title: '作品详情',
    navigation: true,
  },
  children: [
    {
      path: `:id(\\d+)/:audit(${IdentityType.NORMAL}|${IdentityType.AUDIT})`,
      name: RouteName.EDITOR,
      props: true,
      components: {
        default: () => import('@views/editor/EditorPage.vue'),
        sider: () => import('@components/side/EditorSider.vue'),
      },
    },
  ],
}
