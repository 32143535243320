import { Layout, RouteName } from '@/define'
import { RouteRecordRaw } from 'vue-router'

export default <RouteRecordRaw>{
  path: '/apply',
  component: Layout.DEFAULT,
  meta: {
    requiresLogin: true,
    title: '申请入驻',
  },
  children: [
    {
      path: '',
      name: RouteName.APPLY,
      component: () => import('@views/apply/ApplyPage.vue'),
    },
  ],
}
