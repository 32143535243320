import { Layout, NoticeMode, RouteName } from '@/define'
import { RouteRecordRaw } from 'vue-router'

export default <RouteRecordRaw>{
  path: '/message',
  component: Layout.CUSTOM,
  meta: {
    requiresLogin: true,
    title: '消息中心',
    navigation: true,
  },
  children: [
    {
      path: `:type(${NoticeMode.ALL}|${NoticeMode.PUBLIC}|${NoticeMode.SYSTEM})`,
      props: true,
      name: RouteName.MESSAGE,
      components: {
        default: () => import('@views/message/MessagePage.vue'),
        sider: () => import('@components/side/MessageSider.vue'),
      },
    },
  ],
}
